<template>
    <div class="sup_content sup_user_management">
        <div class="sup_page_heading">
            <h2>User Management</h2>
            <Button v-if="userAdd" icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-primary" v-tooltip="'Add User'" @click="userModal.addUserModal = true" />
        </div>
        <div class="sup_inner_content">
            <!--- Show User Table Start --->
            <DataTable class="p-datatable" :paginator="true" :value="allUserData.data" :rowHover="true" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" removableSort :loading="allUserData.loading" responsiveLayout="scroll" :filters="filters">
                <template #loading>
                    Loading customers data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <div class="sup_list_action">
                            <Button v-if="userDeactivateList" :label="allUserData.userStatus === 'active' ? 'Deactive List': 'Active List'" :icon="['pi', allUserData.userStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allUserData.userStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-mr-2']" @click="fetchAllUser(allUserData.userStatus, 'userStatus')" />
                        </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allUserData.searchUser" placeholder="Search User" @keyup.enter="fetchAllUser(allUserData.userStatus, 'search')"/>
                            </span>
                            <Calendar v-model="allUserData.dateRange" dateFormat="dd.mm.yy" placeholder="DD.MM.YY" selectionMode="range" class="sup_button p-ml-2" inputClass="p-inputtext" :manualInput="false" :showIcon="true" @date-select="allUserData.dateRange[1] != null ? fetchAllUser(allUserData.userStatus, 'dateRange') : ''" plceholder="abc" />
                            <Button v-if="allUserData.searchUser !== null || allUserData.dateRange !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllUser(allUserData.userStatus, 'resetFilters')"/>
                            <SplitButton v-if="userListExport" label="Export" icon="pi pi-external-link" class="p-button-secondary p-ml-2" :model="exportType"></SplitButton>
                        </div>
                    </div>
                </template>
                <Column field="name" header="Name" headerStyle="width: 12rem" :sortable="true"></Column>

                <Column field="email" header="Email" headerStyle="width: 17rem" :sortable="true"></Column>

                <Column field="Role" header="Role" :sortable="true">                  
                    <template #body="slotProps">
                        {{slotProps.data.Role.description}}
                    </template>
                </Column>

                <Column field="status" header="Status">                    
                    <template #body="slotProps">
                        <div :class="['sup_user_status', slotProps.data.status === true ? 'sup_user_status_active' : 'sup_user_status_deactive']">
                            <i :class="['pi', slotProps.data.status === true ? 'pi-check' : 'pi-times']" />{{ slotProps.data.status === true ? 'Active' : 'Deactive' }}
                        </div>
                    </template>
                </Column>

                <Column field="lastLogin" header="Last Login" headerStyle="width: 12rem" :sortable="true">
                    <template #body="slotProps">
                        <!-- {{slotProps.data.lastLogin.split("T")[0]}}
                        {{slotProps.data.lastLogin.split("T")[1].split(".")[0]}} -->
                        {{usFormatDate(slotProps.data.createdAt)}}
                    </template>
                </Column>

                <Column field="userId" header="Actions">
                    <template #body="slotProps">
                        <Button v-if="userEdit ? slotProps.data.status : false" type="button" icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-primary p-mr-1" @click="viewEditUser(slotProps.data)" v-tooltip="'View & Edit User'" />
                        <Button v-if="userDeactivate ? slotProps.data.status : false" type="button" icon="pi pi-ban" class="p-button-sm p-button-rounded p-button-danger p-mr-1" @click="confimationModal(slotProps.data, 'de-active')" v-tooltip="'Deactivate User'" />
                        <Button v-if="!slotProps.data.status" icon="pi pi-undo" class="p-button-secondary p-button-rounded p-button-sm p-mr-1" @click="confimationModal(slotProps.data, 'restore')" v-tooltip="'Restore User'" />
                        <Button v-if="!slotProps.data.status" icon="pi pi-trash" class="p-button-danger p-button-rounded p-button-sm" @click="deleteUser(slotProps.data, 'delete')" v-tooltip="'Delete User'" />
                        <Button v-if="userSetPrivilage" icon="pi pi-link" class="p-button-info p-button-rounded p-button-sm" v-tooltip="'Set Privilege'" />
                    </template>
                </Column>
            </DataTable>
            <!--- Show User Table End --->
        </div>
        <!--- Add User Modal Start --->
        <Dialog header="Add New User" v-model:visible="userModal.addUserModal" :modal="true" :style="{width: '600px'}">
            <form class="sup_user_add" @submit.prevent="createUser()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_name">Full Name<span class="text-danger">*</span></label>
                        <InputText v-model="addEditUser.name" id="u_name" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userNameEmpty">Name is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_email">Email Address<span class="text-danger">*</span></label>
                        <InputText v-model="addEditUser.email" id="u_email" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userEmailEmpty">Email is not available.</small>
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userEmailInvalid">Email is not valid.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_mobile">Mobile Number<span class="text-danger">*</span></label>
                        <InputText v-model="addEditUser.phone" id="u_mobile" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userMobileEmpty">Mobile Number is not available.</small>
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userMobileInvalid">Mobile Number is not valid.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_role">Role<span class="text-danger">*</span></label>
                        <Dropdown v-model="addEditUser.role" :options="allroleData.data" id="u_role" optionLabel="description" optionValue="uuid" placeholder="Select a Role" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userRoleEmpty">Selact a Role.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_company">Company<span class="text-danger">*</span></label>
                        <InputText v-model="addEditUser.company" id="u_company" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userCompanyEmpty">Company is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_intro">intro</label>
                        <InputText v-model="addEditUser.intro" id="u_intro" type="text" />
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_country">Country<span class="text-danger">*</span></label>
                        <Dropdown v-model="addEditUser.country" :options="alllocationData.country" id="u_country" optionLabel="name" optionValue="uuid" placeholder="Select a Country" @change="fetchLocation('countryOnchange')" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userCountryEmpty">Selact a Country.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_state">State<span class="text-danger">*</span></label>
                        <Dropdown v-model="addEditUser.state" :options="alllocationData.state" id="u_state" optionLabel="name" optionValue="uuid" placeholder="Select a State" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userStateEmpty">Selact a State.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_city">City</label>
                        <InputText v-model="addEditUser.city" id="u_city" type="text" />
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_zip">ZipCode</label>
                        <InputText v-model="addEditUser.zipCode" id="u_zip" type="text" />
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_password">Password<span class="text-danger">*</span></label>
                        <InputText v-model="addEditUser.password" id="u_password" type="password" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userPasswordEmpty">Password is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_confirm_password">Confirm Password<span class="text-danger">*</span></label>
                        <InputText v-model="addEditUser.confirmPassword" id="u_confirm_password" type="password" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userConfrmPasswordEmpty">Conform Password is not available.</small>
                        <small class="p-invalid sup_text_danger" v-if="!addEditvalidationError.userConfrmPasswordEmpty && addEditvalidationError.userPasswordMissmatch">Password Does Not Match.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_address_1">Address 1</label>
                        <InputText v-model="addEditUser.address1" id="u_address_1" type="text" />
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_address_2">Address 2</label>
                        <InputText v-model="addEditUser.address2" id="u_address_2" type="text" />
                    </div>
                </div>
                <div class="p-fluid">
                    <Button type="submit" label="Add" class="btn-primary" />
                </div>
                <div :class="['sup_form_massage p-mt-3', allUserData.addStatus === 200 ? 'success' : 'error']" v-if="allUserData.addStatus !== ''">{{allUserData.addMessage}}</div >
            </form>
        </Dialog>
        <!--- Add User Modal End --->
        <!--- Edit User Modal Start --->
        <Dialog v-model:visible="userModal.editUserModal" :modal="true" :style="{width: '600px'}" @hide="closeModal()">
            <template #header>
                <span class="p-dialog-title">Edit {{modalUserdata.value.name}}</span>
            </template>
            <form class="sup_user_edit" @submit.prevent="updateUser(modalUserdata.value.uuid)">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_name">Full Name*</label>
                        <InputText v-model="addEditUser.name" id="u_name" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userNameEmpty">Name is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_email">Email Address*</label>
                        <InputText v-model="addEditUser.email" id="u_email" type="text" readonly />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userEmailEmpty">Email is not available.</small>
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userEmailInvalid">Email is not valid.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_mobile">Mobile Number*</label>
                        <InputText v-model="addEditUser.phone" id="u_mobile" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userMobileEmpty">Mobile Number is not available.</small>
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userMobileInvalid">Mobile Number is not valid.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_role">Role*</label>
                        <Dropdown v-model="addEditUser.role" :options="allroleData.data" id="u_role" optionLabel="description" optionValue="uuid" placeholder="Select a Role" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userRoleEmpty">Selact a Role.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_company">Company*</label>
                        <InputText v-model="addEditUser.company" id="u_company" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userCompanyEmpty">Company is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_intro">intro</label>
                        <InputText v-model="addEditUser.intro" id="u_intro" type="text" />
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_country">Country*</label>
                        <Dropdown v-model="addEditUser.country" :options="alllocationData.country" id="u_country" optionLabel="name" optionValue="uuid" placeholder="Select a Country" @change="fetchLocation('onchange')" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userCountryEmpty">Selact a Country.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_state">State*</label>
                        <Dropdown v-model="addEditUser.state" :options="alllocationData.state" id="u_state" optionLabel="name" optionValue="uuid" placeholder="Select a State" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.userStateEmpty">Selact a State.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_city">City</label>
                        <InputText v-model="addEditUser.city" id="u_city" type="text" />
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_zip">ZipCode</label>
                        <InputText v-model="addEditUser.zipCode" id="u_zip" type="text" />
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_address_1">Address 1</label>
                        <InputText v-model="addEditUser.address1" id="u_address_1" type="text" />
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_address_2">Address 2</label>
                        <InputText v-model="addEditUser.address2" id="u_address_2" type="text" />
                    </div>
                </div>
                <div class="p-fluid">
                    <Button type="submit" label="Submit" class="btn-primary" />
                </div>
                <div :class="['sup_form_massage p-mt-3', allUserData.editStatus === 200 ? 'success' : 'error']" v-if="allUserData.editStatus !== ''">{{'User has been updated ' + allUserData.editMessage}}</div >
            </form>
        </Dialog>
        <!--- Edit User Modal End --->
        <!--- Deactive/Restore Confirmation Start --->
        <Dialog header="Confirmation" v-model:visible="confirmationModal.modalState" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Do you want to proceed?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="confirmationModal.modalState = false" class="p-button-text" autofocus/>
                <Button label="Yes" icon="pi pi-check" @click="deactiveAndRestoreUser(confirmationModal.modaldata.data, confirmationModal.modaldata.load), confirmationModal.modalState = false" class="p-button-primary" />
            </template>
        </Dialog>
        <!--- Deactive/Restore Confirmation End --->
    </div>
</template>

<script>
import { allUser, allRole, allLocation, userById, userExport} from '../../../../config/appUrls';
import { apiKey } from '../../../../config/constant';
import userLogout from '../../../../helper/userLogout/userLogout';
import { validateEmail, validateMobile, validatePassword, matchPassword } from "../../../../helper/validationProcess/ValidationProcess";
import {currentMenuUuid} from '../../../../helper/pageActions/pageActions';
import { useStore } from 'vuex';
import { computed, onBeforeMount, reactive } from 'vue';
import axios from 'axios';
import {usFormatDate } from '../../../../helper/utility/utility';
const FileSaver = require('file-saver');

export default {
    name: 'UserManagement',
    setup(){
        //Application store call
        const store = useStore();

        //Page Privilages data state
        const pagePrivilages = reactive({
            userRole: '',
            uuid: '',
            actions: ''
        });

        //User initial data storing state
        const allUserData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            userStatus: 'active',
            searchUser: null,
            dateRange: null,
            addStatus: '',
            addMessage: '',
            editStatus: '',
            editMessage: '',
        })

        //All modal open/close handling state
        const userModal = reactive({
            addUserModal: false,
            editUserModal: false
        });

        //Edit Modal data state 
        const modalUserdata = reactive({});

        //Coditional page action state update to show action conditionally
        const userAdd = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.usermanagementAdd);
        const userEdit = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.usermanagementEdit);
        const userDeactivate = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.usermanagementDeactivate);
        const userDeactivateList = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.usermanagementDeactivateList);
        const userSetPrivilage = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.usermanagementSetPrivilage);
        const userListExport = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.usermanagementExport);

        //ADD and EDIT User input binding state
        const addEditUser = reactive ({
            name: '',
            email: '',
            phone: '',
            company: '',
            city: '',
            zipCode: '',
            address1: '',
            address2: '',
            country: '',
            state: '',
            role: '',
            intro: '',
            password: '',
            confirmPassword: ''
        })

        //Form validation error state
        const addEditvalidationError = reactive({
            userNameEmpty: false,
            userEmailEmpty: false,
            userEmailInvalid: false,
            userMobileEmpty: false,
            userMobileInvalid: false,
            userCompanyEmpty: false,
            userRoleEmpty: false,
            userCountryEmpty: false,
            userStateEmpty: false,
            userPasswordEmpty: false,
            userConfrmPasswordEmpty: false,
            userPasswordMissmatch: false,
        });

        //All role state
        const allroleData = reactive({
            data: null,
            status: null,
        })

        //Location state
        const alllocationData = reactive({
            country: null,
            state: null,
            status: null,
        });

        //Export type array and self call back function
        const exportType = reactive([
            {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                command: () => {
                    exportXL('excel');
                }
            },
            {
                label: 'PDF',
                icon: 'pi pi-file-pdf',
                command: () => {
                    exportXL('pdf');
                }
            }
        ]);

        // Confirm modal state
        const confirmationModal = reactive({
            modalState: false,
            modaldata: ''
        });

        /**
         * Self calling fucntion on compement initiation
         */
        (async () => {
            /**
             * calling currentMenuUuid() with current page name as argument
             * And return back available menu action acess and role
             */
            let { userRole, menuActions } = await currentMenuUuid('UserManagement');
            pagePrivilages.userRole = userRole;
            pagePrivilages.actions = menuActions;
        })();
        
        //On component mount event call
        onBeforeMount( () => {
            fetchAllUser('active');
            fetchRoleList();
            fetchLocation('intialLoad');
        });

        /**
         * Fetch all Active/Deactive User with one handler
         * userStatus - param is for if fetching active list or deactive list, default is active
         * load - where this function is calling
         * let data = getting fresh date range on filtering dates from UI and passing on api params
         * after success on api storing user list to local variable
         * 
         * if catch(err) the if error status 403 the logging user out form the portal
         */
        const fetchAllUser = async (userStatus, load) => {
            let date = '';
            if(load === 'userStatus'){ // if userStatus toggle user list
                allUserData.userStatus = userStatus === 'active' ? 'de-active' : 'active' ;
            }

            if(load === 'resetFilters'){ // if load resetFilters the reset all api param
                allUserData.searchUser = null;
                allUserData.dateRange = null;
            }

            if (load === 'dateRange') { //if dateRange then update scope variable
                date = formatDate(allUserData.dateRange[0]) + ' ' + formatDate(allUserData.dateRange[1]);
            }

            //start api calling
            try{
                allUserData.loading = !allUserData.loading;
                const response = await axios.get( allUser, {
                    params: {
                        status: allUserData.userStatus,
                        search: allUserData.searchUser,
                        filterDt: date
                    }, 
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                    allUserData.data = response.data.data.users;
                    allUserData.total = response.data.data.total;
                    allUserData.loading = !allUserData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout(); // log out user
                } else {
                    console.error('error', err);
                }
            }
        }

        /**
         * updating user input value of a perticular user data
         * userById - api for fetcing percular user data
         * 
         * On success update local variable with response data and popout edit modal
         * and also calling fetchLocation() to fetch location with onchange value to get state list
         */
        const viewEditUser = async (slotData) => {

            const response = await axios.get( userById, {
                params: {
                    uuid: slotData.uuid,
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if (response.data.status === 200) {
                //allroleData.data = response.data.data;
                userModal.editUserModal = true;
                modalUserdata.value = slotData;
                addEditUser.name = response.data.data.name;
                addEditUser.email = response.data.data.email;
                addEditUser.email = response.data.data.email;
                addEditUser.phone = response.data.data.mobile;
                addEditUser.company = response.data.data.company;
                addEditUser.city = response.data.data.city;
                addEditUser.zipCode = response.data.data.zipcode;
                addEditUser.address1 = response.data.data.addressOne;
                addEditUser.address2 = response.data.data.addressTwo;
                addEditUser.country = response.data.data.countryId;
                addEditUser.state = response.data.data.stateId;
                addEditUser.role = response.data.data.roleId;
                addEditUser.intro = response.data.data.intro;

                fetchLocation('onchange'); //onchange argument user for fetching state list
            }

        }

        /**
         * At the time create new user first check all field are filled the call api with filled data
         * Also show api respose on UI
         * 
         * allUser - Api form create new user
         */
        const createUser = async() => {
            const isValidate = fromValidation();
            if (isValidate) {
                try{
                    const response = await axios.post( allUser,{
                        roleId: addEditUser.role,
                        countryId: addEditUser.country,
                        stateId: addEditUser.state,
                        name: addEditUser.name,
                        email: addEditUser.email,
                        password: addEditUser.password,
                        mobile: addEditUser.phone,
                        company: addEditUser.company,
                        city: addEditUser.city,
                        addressOne: addEditUser.address1,
                        addressTwo: addEditUser.address2,
                        zipcode: addEditUser.zipCode,
                        intro: addEditUser.intro,
                        profile: '',
                    },{
                        headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        }, 
                    });
                    if(response.status === 200) {
                        allUserData.addStatus = response.data.status;
                        allUserData.addMessage = response.data.data;
                        fetchAllUser('active'); // Reloading fetchUser api to show new user on the list
                        addEditUserFormReset(); //at the end reset the full form values
                    }
                } catch(err){
                    allUserData.addStatus = err.response.data.status;
                    allUserData.addMessage = err.response.data.message;
                }
            }
        }

        //All form Validation one by one
        const fromValidation = () => {
            const emailValid = validateEmail(addEditUser.email);
            const mobileValid = validateMobile(addEditUser.phone);

            if (!addEditUser.name) {
                addEditvalidationError.userNameEmpty = true;
            } else {
                addEditvalidationError.userNameEmpty = false;
            }

            if (!emailValid.lValidation) {
                addEditvalidationError.userEmailEmpty = true;
            } else if (emailValid.lValidation && !emailValid.eValidation){
                addEditvalidationError.userEmailEmpty = false;
                addEditvalidationError.userEmailInvalid = true;
            } else {
                addEditvalidationError.userEmailEmpty = false;
                addEditvalidationError.userEmailInvalid = false;
            }

            if (!mobileValid.lValidation) {
                addEditvalidationError.userMobileEmpty = true;
            } 
            // else if (mobileValid.lValidation && !mobileValid.mValidation){
            //     addEditvalidationError.userMobileEmpty = false;
            //     addEditvalidationError.userMobileInvalid = true;
            // }
            else {
                addEditvalidationError.userMobileEmpty = false;
                addEditvalidationError.userMobileInvalid = false;
            }

            if(!validatePassword(addEditUser.password).lValidation){
                addEditvalidationError.userPasswordEmpty = true;
            } else {
                addEditvalidationError.userPasswordEmpty = false;
            }

            if(!validatePassword(addEditUser.confirmPassword).lValidation){
                addEditvalidationError.userConfrmPasswordEmpty = true;
            } else {
                addEditvalidationError.userConfrmPasswordEmpty = false;
            }

            if(!matchPassword(addEditUser.password, addEditUser.confirmPassword)) {
                addEditvalidationError.userPasswordMissmatch = true;
            }else {
                addEditvalidationError.userPasswordMissmatch = false;
            }

            if (!addEditUser.company) {
                addEditvalidationError.userCompanyEmpty = true;
            } else {
                addEditvalidationError.userCompanyEmpty = false;
            }

            if (!addEditUser.role) {
                addEditvalidationError.userRoleEmpty = true;
            } else {
                addEditvalidationError.userRoleEmpty = false;
            }

            if (!addEditUser.country) {
                addEditvalidationError.userCountryEmpty = true;
            } else {
                addEditvalidationError.userCountryEmpty = false;
            }

            if (!addEditUser.state) {
                addEditvalidationError.userStateEmpty = true;
            } else {
                addEditvalidationError.userStateEmpty = false;
            }

            if (!addEditvalidationError.userNameEmpty && !addEditvalidationError.userMobileEmpty && !addEditvalidationError.userMobileInvalid && !addEditvalidationError.userEmailEmpty && !addEditvalidationError.userEmailInvalid && !addEditvalidationError.userPasswordEmpty && !addEditvalidationError.userPasswordMissmatch && !addEditvalidationError.userCompanyEmpty && !addEditvalidationError.userRoleEmpty && !addEditvalidationError.userCountryEmpty && !addEditvalidationError.userStateEmpty) {
                return true;
            } else {
                return false;
            }
        }

        //All modal close function needfull steps
        const closeModal = () => {
            addEditUserFormReset();
            allUserData.addStatus= '',
            allUserData.addMessage= '',
            allUserData.editStatus = '';
            allUserData.editMessage = '';
        }

        //Form reset
        const addEditUserFormReset = () => {
            addEditUser.name = '';
            addEditUser.email = '';
            addEditUser.phone = '';
            addEditUser.company = '';
            addEditUser.city = '';
            addEditUser.zipCode = '';
            addEditUser.address1 = '';
            addEditUser.address2 = '';
            addEditUser.country = '';
            addEditUser.state = '';
            addEditUser.role = '';
            addEditUser.intro = '';
            addEditUser.password = '';
            addEditUser.confirmPassword = '';
        }

        /**
         * Indevidual users deactive and restore method
         * sloteData - bring perticular user data to get UUID
         * type - delete or restore, if delete then delete api method or patch method
         * 
         * api api success fetcing user data again
         */
        const deactiveAndRestoreUser = async(sloteData, type) => {
            try{
                const options = {
                    //if de-active call then delete method else patch to restore user
                    method: type === 'de-active' ? 'delete' : 'patch',
                    url: allUser,
                    params: {
                        uuid: sloteData.uuid
                    },
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                await axios(options); // API calling here with options

                fetchAllUser('active'); // fetching user data
            } catch(err){
                console.log(err);
            }
        }

        //Roles list for new user role dropdown
        const fetchRoleList = async() => {
            const response = await axios.get( allRole, {
                params: {
                    status: 'active',
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if (response.data.status === 200) {
                allroleData.data = response.data.data;
            }
        }

        /**
         * Fetching location list
         * 
         * load - bring 'intialLoad' or 'onchange'
         * 
         * if 'intialLoad' then response will come with country list
         * if 'onchange' then response will come with state list
         * 
         */
        const fetchLocation = async(load) => {
            const response = await axios.get( allLocation, {
                params: {
                    uuid: addEditUser.country,
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });
            
            if (response.data.status === 200) {
                //If condition for country and state segrigation
                if (load === 'intialLoad') {
                    alllocationData.country = response.data.data;
                } else {
                    alllocationData.state = response.data.data;
                }
            }
        }

        /**
         * updating user data and call fetch user function again with updated list
         */
        const updateUser = async (uuid) => {
            try{
                const options = {
                    method: 'put',
                    url: allUser,
                    params: {
                        uuid: uuid
                    },
                    data: JSON.stringify({
                        roleId: addEditUser.role,
                        countryId: addEditUser.country,
                        stateId: addEditUser.state,
                        name: addEditUser.name,
                        email: addEditUser.email,
                        mobile: addEditUser.phone,
                        company: addEditUser.company,
                        city: addEditUser.city,
                        addressOne: addEditUser.address1,
                        addressTwo: addEditUser.address2,
                        zipcode: addEditUser.zipCode,
                        intro: addEditUser.intro,
                        profile: '',
                    }),
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                let response = await axios(options); // calling API
                allUserData.editStatus = response.data.status;
                allUserData.editMessage = response.data.message;
                fetchAllUser('active'); //fetching update user list
            } catch(err){
                console.log('err', err);
            }
        }

        //Export User List
        const exportXL = async(load) => {
            let date = null;
            if (load === 'excel') {
                try{
                    // allUserData.limit = "";
                    // allUserData.page = "";
                    // + '&page=' + allUserData.page + '&limit=' + allUserData.limit + '&sortBy=' + allUserData.sortBy + '&sortOrder='+ allUserData.sortOrder
                    //const queryUrl = userExport + '?status=' + allUserData.status + '&searchData' + allUserData.searchUser ;
                    if (allUserData.dateRange !== null) {
                        date = formatDate(allUserData.dateRange[0]) + ' ' + formatDate(allUserData.dateRange[1]);
                    }
                    const response = await axios.get( userExport, {
                        responseType: 'arraybuffer',
                        params: {
                            status: allUserData.userStatus,
                            search: allUserData.searchUser,
                            filterDt: date
                        },headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        },
                    });
                    if(response.status === 200) {
                        const dirtyFileName = response.headers['content-disposition'];
                        const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                        var fileName = dirtyFileName.match(regex)[3];
                        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        FileSaver(blob, fileName);
                    }
                } catch(err) {
                    console.log('error', err);
                }
            }
            if (load === 'pdf') {
                try{
                    if (allUserData.dateRange !== null) {
                        date = formatDate(allUserData.dateRange[0]) + ' ' + formatDate(allUserData.dateRange[1]);
                    }
                    const response = await axios.get( userExport, {
                        responseType: 'arraybuffer',
                        params: {
                            status: allUserData.userStatus,
                            search: allUserData.searchUser,
                            filterDt: date
                        },headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        },
                    });
                    if(response.status === 200) {
                        const dirtyFileName = response.headers['content-disposition'];
                        const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                        var fileName1 = dirtyFileName.match(regex)[3];
                        var blob1 = new Blob([response.data], { type: 'application/pdf' });
                        FileSaver(blob1, fileName1);
                    }
                } catch(err) {
                    console.log('error', err);
                }
            }
        }

        //Filtering date format
        const formatDate = (date) => {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        }

        /**
         * On the time of delete/restore we give a alert to double check user 
         */
        const confimationModal = (sloteData, load) => {
            confirmationModal.modalState = true;
            confirmationModal.modaldata = {
                data: sloteData,
                load: load
            }
        }

        // Returing all variable and fucntion to access on template
        return{
            allUserData,
            userModal,
            addEditUser,
            modalUserdata,
            addEditvalidationError,
            allroleData,
            alllocationData,
            exportType,
            confirmationModal,
            userAdd,
            userEdit,
            userDeactivate,
            userDeactivateList,
            userSetPrivilage,
            userListExport,
            fetchAllUser,
            viewEditUser,
            createUser,
            deactiveAndRestoreUser,
            fetchLocation,
            closeModal,
            updateUser,
            exportXL,
            confimationModal,
            usFormatDate
        }
    }
}
</script>

<style lang="scss">
.p-tieredmenu{
    width: 141px;
}
</style>
<style lang="scss" scoped>
@import "../../../../assets/style/component/superAdmin/pages/rolesPrivileges/userManagement";
</style>